
const NAVBAR = {
    PL : {
        'Home' : 'STRONA GŁÓWNA',
        'Galery' : 'GALERIA',
        'About_us' : 'O NAS',
        'Contact' : 'KONTAKT',
        'Bigger_letters' : 'WIĘKSZE LITERY',
    },
    EN : {
        'Home' : 'HOME',
        'Galery' : 'GALERY',
        'About_us' : 'ABOUT US',
        'Contact' : 'CONTACT',
        'Bigger_letters' : 'BIGGER LETTERS',
    }
}

export {NAVBAR};