import { Grid, Typography } from "@mui/material";
import styles from "./About.module.css";
import about_us_image from '../../Images/About/background.jpg';
import ProcessCard from "./ProcessCard";
import { useContext, useEffect, useState } from "react";
import { ABOUT_SECTION_CARDS } from "../Constants/AboutSection";
import NavigateContext from "../../Context/NavigateContext";
const About =() =>{
    const navigateContext = useContext(NavigateContext)
    useEffect(()=>{
        checkNavbar()
        window.scrollTo(0,0)
    },[])
    const [progressFill, setProgressFill] = useState()
    window.onscroll = ()=> {setProgressFill((window.scrollY / document.documentElement.scrollHeight) * 100); checkNavbar()}


    const checkNavbar =()=>{
        const navbar_desktop = document.getElementById('navbar_desktop')
        const navbar_mobile = document.getElementById('navbar_mobile')
        const bigger_letters = document.getElementById('bigger_letters')
        const chosen_link_desktop = document.querySelector('.navigation_link_chosen')
        const chosen_link_mobile = document.querySelector('.navigation_link_mobile_chosen')
        if(window.scrollY === 0){
            navbar_desktop.classList.add('navbar_desktop_top')
            navbar_mobile.classList.add('navbar_mobile_top')
            chosen_link_desktop.classList.add('link_on_top')
            chosen_link_mobile.classList.add('link_on_top')
            bigger_letters.classList.add('bigger_letters_on_top')
            }
        else if(navbar_desktop.classList.contains('navbar_desktop_top')){
            navbar_desktop.classList.remove('navbar_desktop_top')
            chosen_link_desktop.classList.remove('link_on_top')
            bigger_letters.classList.remove('bigger_letters_on_top')
        }
        else if (navbar_mobile.classList.contains('navbar_mobile_top')){
            navbar_mobile.classList.remove('navbar_mobile_top')
            chosen_link_mobile.classList.remove('link_on_top')
        }
    }

    useEffect(()=>{
        const rightCards = document.querySelectorAll('.card_right')
        const leftCards = document.querySelectorAll('.card_left')
        const rightCardsObserver = new IntersectionObserver((entries)=>{
            entries.forEach(entry=>{
                entry.target.classList.toggle('right_card_activate', entry.isIntersecting);
            })
        },{threshold:.3})
        const leftCardsObserver = new IntersectionObserver((entries)=>{
            entries.forEach(entry=>{
                entry.target.classList.toggle('left_card_activate', entry.isIntersecting);
            })
        },{threshold:.3})
        rightCards.forEach(card=>{
            rightCardsObserver.observe(card)
        })
        leftCards.forEach(card=>{
            leftCardsObserver.observe(card)
        })
},[])

let counter = 0

    return(     
        <Grid container className={styles.main_container}>
            <Grid container item className={styles.main_image_container} xs={12}>
                <img className={styles.main_image_container} src={about_us_image} loading="lazy"/>
            </Grid>
            <div className={styles.about_main_line}></div>
            <div className={styles.about_main_line_progress} style={{height:`${progressFill*1.1}%`}}></div>

            <Grid container item className={styles.main_process_container} pb={25}>
                {ABOUT_SECTION_CARDS.map((card)=>{
                    counter++
                    return (
                        <Grid xs ={12}><ProcessCard title={card[navigateContext.language].Title} text={card[navigateContext.language].Text} image={card[navigateContext.language].Image} className={counter%2 ? 'card_right' : 'card_left'}/></Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default About;