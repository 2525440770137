import './App.css';
import About from './Components/About/About';
import Homepage from './Components/Homepage/Homepage';
import {Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import NavigateContext from './Context/NavigateContext';
import { useState } from 'react';
import Footer from './Components/Footer/Footer';
import Contact from './Components/Contact/Contact';

const App = () => {

  const [selectedGaleryCategory, setSelectedGaleryCategory] = useState(null)
  const [biggerLetters, setBiggerLetters] = useState(false)
  const [language, setLanguage] = useState('PL')
  const [productModalData, setProductModalData] = useState()
  return (
    <NavigateContext.Provider
    value={{
      selectedGaleryCategory,
      biggerLetters,
      language,
      productModalData,
      setBiggerLetters,
      setSelectedGaleryCategory,
      setLanguage,
      setProductModalData
    }}
    >
    <Navbar/>
    <Routes>
      {/* <Route path="/" element={<>
      <div style={{width:'100vw', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', fontSize:'25px'}}>Prace nad stroną trwają...</div>
      </div>
      </>}></Route> */}
      <Route path="/" element={<Homepage/>}></Route>
      <Route path="/about" element={<About/>}></Route>
      <Route path="/contact" element={<Contact/>}></Route>
    </Routes>
    <Footer/>
    </NavigateContext.Provider>
  );
}

export default App;
