
const MAIN_HOMEPAGE_SWIPER_TEXT_PHOTO_1 = {
    PL : {
        'header' : 'KOLEKCJA Z BRYLANTAMI',
        'text' : 'SPRAWDŹ' 
    },
    EN : {
        'header' : 'COLLECTION WITH DIAMONDS',
        'text' : 'FIND OUT MORE' 
    }
}


const MAIN_HOMEPAGE_SWIPER_TEXT_PHOTO_2 = {
    PL : {
        'header' : 'NIESKAZITELNA PRECYZJA',
        'text' : 'SPRAWDŹ' 
    },
    EN : {
        'header' : 'PERFECT PRECISION',
        'text' : 'FIND OUT MORE' 
    }
}

export {MAIN_HOMEPAGE_SWIPER_TEXT_PHOTO_1, MAIN_HOMEPAGE_SWIPER_TEXT_PHOTO_2};