import ring_9 from '../../Images/Homepage/diamonds_collection/ring_9.jpeg';
import ring_10 from '../../Images/Homepage/diamonds_collection/ring_10.jpeg';
import ring_11 from '../../Images/Homepage/diamonds_collection/ring_11.jpeg';
import ring_12 from '../../Images/Homepage/diamonds_collection/ring_12.jpeg';
import ring_13 from '../../Images/Homepage/diamonds_collection/ring_13.jpeg';
import ring_14 from '../../Images/Homepage/diamonds_collection/ring_14.jpeg';
import ring_15 from '../../Images/Homepage/diamonds_collection/ring_15.jpeg';

const DIAMONDS_COLLECTION_SECTION =[
    {id:0, photo:ring_9, name:'N9'},
    {id:1, photo:ring_10, name:'N10'},
    {id:2, photo:ring_11, name:'N11'},
    {id:3, photo:ring_12, name:'N12'},
    {id:4, photo:ring_13, name:'N13'},
    {id:5, photo:ring_14, name:'N14'},
    {id:6, photo:ring_15, name:'N15'},
]

const DIAMONDS_COLLECTION_SECTION_TEXT = {
    PL : {
        'Title' : 'NATAN COLLECTION',
        'Title_text' : 'BIŻUTERIA Z BRYLANTAMI',
        'Text' : 'Nasza najnowsza kolekcja brylantów łączy w sobie doskonałe rzemiosło z niepowtarzalnym stylem, tworząc wyjątkowe i zachwycające dzieła sztuki. Każdy brylant w naszej kolekcji jest starannie wybrany i wyróżnia się swoją czystością, połyskiem i szlifem. Niezależnie od tego, czy szukasz klasyki w postaci diamentowych obrączek zaręczynowych, czy też nowoczesnych i unikatowych wzorów, nasza kolekcja zaspokoi najbardziej wyrafinowane gusta. Pozwól naszym brylantom dodawać blasku twojemu życiu. Odkryj naszą kolekcję już dziś i znajdź idealny brylant, który wyrazi twoją niepowtarzalną osobowość i styl.',
        'Button_text' : 'SPRAWDŹ',
    },
    EN : {
        'Title' : 'NATAN COLLECTION',
        'Title_text' : 'JEWELLERY WITH DIAMONDS',
        'Text' : 'Our latest diamond collection combines excellent craftsmanship with unique style, creating unique and stunning works of art. Each diamond in our collection is carefully selected and stands out for its purity, shine and cut. Regardless of whether you are looking for classic diamond engagement rings or modern and unique designs, our collection will satisfy the most refined tastes. Let our diamonds add sparkle to your life. Discover our collection today and find the perfect diamond that will express your unique personality and style.',
        'Button_text' : 'FIND OUT MORE',
    }
}

export {DIAMONDS_COLLECTION_SECTION, DIAMONDS_COLLECTION_SECTION_TEXT};