import { Grid, Typography } from "@mui/material";
import styles from './Button.module.css';
import { useContext } from "react";
import NavigateContext from "../../../Context/NavigateContext";

const Button = ({text, onClick, mt, id}) =>{

        const navigateContext = useContext(NavigateContext);

        return(
            <Grid container item xs={12} justifyContent={'center'} alignItems={'center'} mt={mt} id={id}>
                <div className={styles.button} onClick={onClick}>
                    <Typography className={styles.button_text} fontSize={navigateContext.biggerLetters ? '25px !important' : '20px !important'}>{text}</Typography>
                </div>
            </Grid>
        )
}
export default Button;