import { useContext } from 'react';
import './ProductModal.css'
import NavigateContext from '../../../Context/NavigateContext';
import { Grid, Typography } from '@mui/material';
import { AiOutlineClose } from "react-icons/ai";
const ProductModal =()=>{
    const navigateContext = useContext(NavigateContext)
    document.onkeydown = (e) => {
        if(e.key === "Escape") {
            navigateContext.setProductModalData(null); 
            document.body.style.overflow='auto'
        }
    }
    return(
        <div id={'product_modal_main_container'}>
            <Grid container item justifyContent={'end'} xs={12} position={'absolute'}>
                <AiOutlineClose cursor={'pointer'} size={30} onClick={()=>{navigateContext.setProductModalData(null); document.body.style.overflow='auto'}} className={'product_modal_close_menu_icon'}/>
            </Grid>
            <Grid container>

            <Grid container item xs={12} sm={6}>
                    <img className={'product_modal_main_image'} src={navigateContext.productModalData.photo}/>
            </Grid>
            <Grid item xs={12} sm={6} sx={{height:'100%'}}>
                <Grid container item xs={12} justifyContent={'center'} alignItems={'center'} className={'name_container'}>
                    <Typography className={'name'} fontSize={'40px'} fontStyle={'italic'}>{navigateContext.productModalData.name}</Typography>
                </Grid>
            </Grid>
            </Grid>
        </div>
    )
}
export default ProductModal;