import { Grid } from '@mui/material';
import styles from './EarringsSection.module.css'
import { EARRINGS_SECTION_PHOTOS, EARRINGS_SECTION_TEXTS } from '../../Constants/EarringsSection';
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'
import main_earrings from '../../../Images/Homepage/earrings_section/main_earrings.jpeg'
import Button from '../../MODELS/Button/Button';
import SectionTitle from '../../MODELS/SectionTitle/SectionTitle';
import SectionText from '../../MODELS/SectionText/SectionText';
import { useContext, useEffect } from 'react';
import NavigateContext from '../../../Context/NavigateContext';
import './EarringsSection.css'
const EarringsSection =({slidesCount}) =>{   
    const navigateContext = useContext(NavigateContext)
    useEffect(()=>{
        const section = document.getElementById('earrings_section')
        const observer = new IntersectionObserver(entries =>{
            entries.forEach(entry=>{
                entry.target.classList.toggle('earrings_section_active', entry.isIntersecting)
                if(entry.isIntersecting) observer.unobserve(entry.target)
            })
        },{threshold:.3})
        observer.observe(section)
        })
    return (
        <>
        <Grid container item xs={12} mt={5} className={styles.main_container}>
            <Grid container item xs={12} md={10}>

                <Grid container item xs={12} md={6} alignContent={'center'} justifyContent={'flex-start'}  id={'earrings_section'}>
                    <div>
                    <SectionTitle mt={5} title={EARRINGS_SECTION_TEXTS[navigateContext.language].Title} text={EARRINGS_SECTION_TEXTS[navigateContext.language].Title_text}/>
                    <SectionText xs={11} md={8} mt={5} text={EARRINGS_SECTION_TEXTS[navigateContext.language].Text}/>
                    </div>
                    <Button text={EARRINGS_SECTION_TEXTS[navigateContext.language].Button_text} mt={5} />
                </Grid>
                
                <Grid container item xs={12} md={6} alignContent={'center'} justifyContent={'center'} mt={5}>
                    <img className={styles.image} src={main_earrings} alt={'earrings'}/>
                </Grid>

            </Grid>
        <Grid container item justifyContent={'center'} alignItems={'center'} mt={5} xs={12}>
            <div className={styles.swiper_section}>
                <Grid container item xs={12}>
                    <Swiper 
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={30}
                        slidesPerView={slidesCount === 1 ? (navigateContext.biggerLetters ? 1 : 2) : slidesCount}
                        navigation = {true}
                        pagination={{ clickable: true, type:'none' }}
                        loop={true}
                        autoplay={{delay:3000}}
                        speed={1000}
                        >
                        {EARRINGS_SECTION_PHOTOS.map((item)=>{
                            return(
                                <SwiperSlide key={item.id}>
                                    <div onClick={window.screen.width <= 900 ? ()=>{navigateContext.setProductModalData(item); setTimeout(()=>{document.body.style.overflow='hidden'}, 200)} : null}>
                                    {window.screen.width > 900 && 
                                    <div className={styles.hidden_product_info_container}>
                                        <div>
                                            <SectionTitle text={item.name}/>
                                            <Button text={EARRINGS_SECTION_TEXTS[navigateContext.language].Button_text} mt={5} onClick={()=>{navigateContext.setProductModalData(item); setTimeout(()=>{document.body.style.overflow='hidden'}, 200)}} />
                                        </div>
                                    </div>
                                    }
                                    <img className={styles.image} src={item.photo}/>
                                        </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </Grid>
            </div>
        </Grid>
    </Grid>
        </>
    )
}
export default EarringsSection;