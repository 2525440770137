import Konsultacja_z_klientem from '../../Images/About/Konsultacja_z_klientem.jpeg'
import Projektowanie from '../../Images/About/Projektowanie.jpeg'
import Wybór_materiałów from '../../Images/About/Wybór_materiałów.jpeg'
import Przygotowanie_surowców from '../../Images/About/Przygotowanie_surowców.jpeg'
import Odlewanie_i_formowanie from '../../Images/About/Odlewanie_i_formowanie.jpeg'
import Grawerowanie from '../../Images/About/Grawerowanie.jpeg'
import Pozłacanie from '../../Images/About/Pozłacanie.jpeg'
import Oprawianie from '../../Images/About/Oprawianie.jpeg'
import Wykończenie from '../../Images/About/Wykończenie.jpeg'
import Kontrola_jakości from '../../Images/About/Kontrola_jakości.jpeg'
import Dostarczanie_klientowi from '../../Images/About/Dostarczanie_klientowi.jpeg'
const ABOUT_SECTION_CARDS = [

    {
        PL : {
            'Title' : 'KONSULTACJA Z KLIENTEM',
            'Text' : 'Przywiązujemy ogromną wagę do indywidualnych potrzeb i wizji naszych klientów. Rozpoczynamy proces projektowania od dogłębnej konsultacji, podczas której wspólnie z wami ustalamy preferencje dotyczące designu, materiałów i wszelkich innych detali, aby stworzyć dla was idealny pierścień, który będzie doskonale odzwierciedlał wasze osobiste style i historie.',
            'Image':Konsultacja_z_klientem
        },
        EN : {
            'Title' : 'CONSULTATION WITH THE CUSTOMER',
            'Text' : 'We attach great importance to the individual needs and visions of our clients. We begin the design process with an in-depth consultation, during which we work with you to determine your preferences regarding design, materials and all other details to create the perfect ring for you that will perfectly reflect your personal styles and stories.',
            'Image':Konsultacja_z_klientem
        }
    },

    {
        PL : {
            'Title' : 'PROJEKTOWANIE',
            'Text' : 'Nasz doświadczony zespół projektantów jest gotowy przekształcić wasze marzenia w rzeczywistość. Tworzymy dla was rysunki lub modele 3D, które są nie tylko estetyczne, ale także funkcjonalne, dbając o każdy detal, abyście mogli z dumą nosić nasze wyroby.',
            'Image':Projektowanie
        },
        EN : {
            'Title' : 'DESIGNING',
            'Text' : 'Our experienced design team is ready to turn your dreams into reality. We create drawings or 3D models for you that are not only aesthetic, but also functional, taking care of every detail so that you can wear our products with pride.',
            'Image':Projektowanie
        }
    },
    {
        PL : {
            'Title' : 'WYBÓR MATERIAŁÓW',
            'Text' : 'Dzięki bogatemu wyborowi najwyższej jakości materiałów, takich jak złoto, platyna czy srebro, oraz szerokiej gamie kamieni szlachetnych, dajemy wam możliwość stworzenia pierścionka, który będzie idealnie odpowiadał waszym gustom i budżetowi.',
            'Image':Wybór_materiałów
        },
        EN : {
            'Title' : 'SELECTION OF MATERIALS',
            'Text' : 'Thanks to a rich selection of the highest quality materials, such as gold, platinum and silver, and a wide range of precious stones, we give you the opportunity to create a ring that will perfectly suit your tastes and budget.',
            'Image':Wybór_materiałów
        }
    },
    {
        PL : {
            'Title' : 'PRZYGOTOWANIE SUROWCÓW',
            'Text' : 'Nasz proces rozpoczyna się od starannego przygotowania surowców, które stanowią podstawę dla naszych wyrobów. Każdy kruszec jest pieczołowicie oczyszczany i topiony, a kamienie szlachetne są obrabiane, szlifowane i polerowane, aby osiągnąć perfekcyjny wygląd.',
            'Image':Przygotowanie_surowców
        },
        EN : {
            'Title' : 'PREPARATION OF RAW MATERIALS',
            'Text' : 'Our process begins with the careful preparation of raw materials that form the basis for our products. Each metal is carefully cleaned and melted, and the gemstones are processed, ground and polished to achieve a perfect appearance.',
            'Image':Przygotowanie_surowców
        }
    },
    {
        PL : {
            'Title' : 'ODLEW',
            'Text' : 'W naszej pracowni każdy pierścień jest wykonywany indywidualnie, zgodnie z waszymi wytycznymi. Topiony kruszec jest starannie odlewany i formowany, a następnie kształtowany ręcznie przez naszych rzemieślników, aby uzyskać unikalny i dopasowany do waszych oczekiwań wyrób.',
            'Image':Odlewanie_i_formowanie
        },
        EN : {
            'Title' : 'CASTING',
            'Text' : 'In our workshop, each ring is made individually, according to your guidelines. The melted metal is carefully cast and formed, and then shaped by hand by our craftsmen to obtain a unique product tailored to your expectations.',
            'Image':Odlewanie_i_formowanie
        }
    },
    {
        PL : {
            'Title' : 'GRAWEROWANIE',
            'Text' : 'Jeśli pragniecie dodać osobisty akcent do waszego pierścionka, oferujemy profesjonalne usługi grawerowania. Dzięki naszym precyzyjnym technikom grawerowania możecie spersonalizować wasze wyroby poprzez dodanie daty, imion czy sentencji, co sprawi, że stanie się on jeszcze bardziej wyjątkowy.',
            'Image':Grawerowanie
        },
        EN : {
            'Title' : 'ENGRAVING',
            'Text' : 'If you would like to add a personal touch to your ring, we offer professional engraving services. Thanks to our precise engraving techniques, you can personalize your products by adding a date, name or saying, which will make it even more unique.',
            'Image':Grawerowanie
        }
    },
    {
        PL : {
            'Title' : 'ZŁOCENIE',
            'Text' : 'W naszej pracowni oferujemy również usługi złocenia lub rodzenia, które nadają waszemu pierścionkowi dodatkowy połysk i elegancję. Proces ten jest przeprowadzany z najwyższą starannością, aby zapewnić trwałość i doskonały wygląd waszego wyrębu.',
            'Image':Pozłacanie
        },
        EN : {
            'Title' : 'GOLD PLATING',
            'Text' : 'In our studio, we also offer gold plating or plating services, which give your ring additional shine and elegance. This process is carried out with the utmost care to ensure the durability and perfect appearance of your logs.',
            'Image':Pozłacanie
        }
    },
    {
        PL : {
            'Title' : 'OPRAWIANIE',
            'Text' : 'Nasz zespół specjalistów dba o to, aby kamienie szlachetne były precyzyjnie osadzone w waszych pierścionkach, zapewniając im trwałość i bezpieczeństwo. Dzięki naszym specjalistycznym narzędziom i doświadczeniu możecie mieć pewność, że wasz pierścień będzie wyglądał zachwycająco przez wiele lat.',
            'Image':Oprawianie
        },
        EN : {
            'Title' : 'BINDING',
            'Text' : 'Our team of specialists ensures that the gemstones are precisely set in your rings, ensuring their durability and safety. Thanks to our specialized tools and experience, you can be sure that your ring will look stunning for many years.',
            'Image':Oprawianie
        }
    },
    {
        PL : {
            'Title' : 'WYKOŃCZENIE',
            'Text' : 'Nasze wyroby przechodzą przez proces dokładnego szlifowania, polerowania i oczyszczania, aby uzyskać perfekcyjny wygląd i połysk. Dbałość o każdy detal w procesie wykończenia sprawia, że wasze pierścionki prezentują się nie tylko pięknie, ale także trwale i wyjątkowo.',
            'Image':Wykończenie
        },
        EN : {
            'Title' : 'FINISH',
            'Text' : 'Our products go through a process of thorough grinding, polishing and cleaning to obtain a perfect appearance and shine. Attention to every detail in the finishing process makes your rings look not only beautiful, but also durable and unique.',
            'Image':Wykończenie
        }
    },
    {
        PL : {
            'Title' : 'KONTROLA JAKOŚCI',
            'Text' : 'Przed dostarczeniem waszych pierścionków dokładnie sprawdzamy ich jakość, zapewniając, że każdy detal jest perfekcyjny. Nasza firma jest dumnym gwarantem najwyższej jakości i precyzji w każdym etapie produkcji, abyście mogli cieszyć się naszymi wyrobami z pełnym zaufaniem i satysfakcją.',
            'Image':Kontrola_jakości
        },
        EN : {
            'Title' : 'QUALITY CONTROL',
            'Text' : 'Before delivering your rings, we carefully check their quality, ensuring that every detail is perfect. Our company is a proud guarantor of the highest quality and precision at every stage of production, so that you can enjoy our products with full confidence and satisfaction.',
            'Image':Kontrola_jakości
        }
    },
    {
        PL : {
            'Title' : 'DOSTARCZENIE KLIENTOWI',
            'Text' : 'Po akceptacji przez was gotowych wyrobów, dostarczamy je do waszych drzwi wraz z wszelkimi dokumentami i certyfikatami. Wasza satysfakcja jest dla nas najważniejsza, dlatego dokładamy wszelkich starań, abyście mogli cieszyć się waszymi nowymi, wyjątkowymi pierścionkami przez wiele lat.',
            'Image':Dostarczanie_klientowi
        },
        EN : {
            'Title' : 'DELIVERY TO THE CUSTOMER',
            'Text' : 'After you accept the finished products, we deliver them to your door along with all documents and certificates. Your satisfaction is the most important to us, which is why we make every effort to ensure that you can enjoy your new, unique rings for many years.',
            'Image':Dostarczanie_klientowi
        }
    }

]

export {ABOUT_SECTION_CARDS};