import { Grid } from "@mui/material"
import SectionTitle from "../../MODELS/SectionTitle/SectionTitle"
import styles from './NewCollectionSection.module.css'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules"
import Button from "../../MODELS/Button/Button"
import {NEW_COLLECTION_SECTION, NEW_COLLECTION_SECTION_TEXT} from "../../Constants/NewCollectionSection"
import { Swiper, SwiperSlide } from "swiper/react"
import { useContext } from "react"
import NavigateContext from "../../../Context/NavigateContext"
const NewCollectionSection = ({slidesCount}) =>{
    const navigateContext = useContext(NavigateContext)
    return(
        <>
        <div>
            <SectionTitle title={NEW_COLLECTION_SECTION_TEXT[navigateContext.language].Title} text={NEW_COLLECTION_SECTION_TEXT[navigateContext.language].Title_text} mt={5}/>

            <Grid container item justifyContent={'center'} alignItems={'center'} mt={5} xs={12}>
                <div className={styles.new_collection_section_container}>
                    <Grid container item xs={12}>
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                            spaceBetween={30}
                            slidesPerView={slidesCount === 1 ? (navigateContext.biggerLetters ? 1 : 2) : slidesCount}
                            navigation = {true}
                            pagination={{ clickable: true, type:'none' }}
                            loop={true}
                            autoplay={{delay:3000}}
                            speed={1000}
                            >
                            {NEW_COLLECTION_SECTION.map((item)=>{
                                return(
                                    <SwiperSlide key={item.id}>
                                        <div onClick={window.screen.width <= 900 ? ()=>{navigateContext.setProductModalData(item); setTimeout(()=>{document.body.style.overflow='hidden'}, 200)} : null}>
                                            {window.screen.width > 900 && 
                                            <div className={styles.hidden_product_info_container}>
                                                <div>
                                                <SectionTitle text={item.name}/>
                                                <Button text={NEW_COLLECTION_SECTION_TEXT[navigateContext.language].Button_text} mt={5} onClick={()=>{navigateContext.setProductModalData(item); setTimeout(()=>{document.body.style.overflow='hidden'}, 200)}}/>
                                                </div>
                                            </div>
                                            }
                                            <img className={styles.new_collection_picture} src={item.photo}/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </Grid>
                </div>
            </Grid>
        </div>
        </>
    )
}
export default NewCollectionSection;