import ring_1 from '../../Images/Homepage/new_collection_section/ring_1.jpeg';
import ring_2 from '../../Images/Homepage/new_collection_section/ring_2.jpeg';
import ring_3 from '../../Images/Homepage/new_collection_section/ring_3.jpeg';
import ring_4 from '../../Images/Homepage/new_collection_section/ring_4.jpeg';
import ring_5 from '../../Images/Homepage/new_collection_section/ring_5.jpeg';
import ring_6 from '../../Images/Homepage/new_collection_section/ring_6.jpeg';
const NEW_COLLECTION_SECTION = [
    {id:0, photo:ring_1, name:'N1'},
    {id:1, photo:ring_2, name:'N2'},
    {id:2, photo:ring_3, name:'N3'},
    {id:3, photo:ring_4, name:'N4'},
    {id:4, photo:ring_5, name:'N5'},
    {id:5, photo:ring_6, name:'N6'},
]

const NEW_COLLECTION_SECTION_TEXT ={
    PL : {
        'Title' : 'NOWA KOLEKCJA',
        'Title_text' : 'WZORY KTÓRE PRZYCIĄGAJĄ OKO',
        'Button_text' : 'SPRAWDŹ',
    },
    EN : {
        'Title' : 'NEW COLLECTION',
        'Title_text' : 'EYE-CATCHING PATTERNS',
        'Button_text' : 'FIND OUT MORE',
    }
}

export {NEW_COLLECTION_SECTION, NEW_COLLECTION_SECTION_TEXT};