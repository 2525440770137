const DIAMONDS_PRODUCTS_SECTION = {
    PL : {
        'Title' : 'NIESPOTYKANY DOTĄD DESIGN',
        'Title_text' : 'WYROBY Z DIAMENTAMI',
        'Text' : 'Oferujemy ekskluzywne pierścionki z diamentami, które wyróżniają się nie tylko doskonałą jakością, ale także niepowtarzalnym designem. Nasze wyroby są starannie wykonane z najwyższej jakości materiałów, takich jak srebro lub złoto, oraz kamieni o doskonałym szlifie i czystości. Każdy pierścień jest wyjątkowy i niepowtarzalny, a nasza oferta obejmuje również możliwość personalizacji, aby stworzyć biżuterię idealnie dopasowaną do indywidualnych preferencji. Oferujemy gwarancję autentyczności oraz satysfakcji, zapewniając naszym klientom nie tylko luksusowy produkt, ale także bezpieczne i satysfakcjonujące doświadczenie zakupowe.',
        'Button_text' : 'Dowiedz się więcej',
    },
    EN : {
        'Title' : 'UNIQUE DESIGN',
        'Title_text' : 'PRODUCTS WITH DIAMONDS',
        'Text' : 'We offer exclusive diamond rings that are distinguished not only by their excellent quality, but also by their unique design. Our products are carefully made of the highest quality materials, such as silver or gold, and stones with excellent cut and purity. Each ring is unique and unique, and our offer also includes the possibility of personalization to create jewelry perfectly suited to individual preferences. We offer a guarantee of authenticity and satisfaction, providing our customers not only with a luxurious product, but also with a safe and satisfying shopping experience.',
        'Button_text' : 'Find out more',
    }
}

export {DIAMONDS_PRODUCTS_SECTION};