import earrings_1 from "../../Images/Homepage/earrings_section/earrings_1.jpeg"
import earrings_2 from "../../Images/Homepage/earrings_section/earrings_2.jpeg"
import earrings_3 from "../../Images/Homepage/earrings_section/earrings_3.jpeg"
import earrings_4 from "../../Images/Homepage/earrings_section/earrings_4.jpeg"
import earrings_5 from "../../Images/Homepage/earrings_section/earrings_5.jpeg"
import earrings_6 from "../../Images/Homepage/earrings_section/earrings_6.jpeg"
import earrings_7 from "../../Images/Homepage/earrings_section/earrings_7.jpeg"


const EARRINGS_SECTION_PHOTOS =[
    {id:0, photo:earrings_1, name:'K1'},
    {id:1, photo:earrings_2, name:'K2'},
    {id:2, photo:earrings_3, name:'K3'},
    {id:3, photo:earrings_4, name:'K4'},
    {id:4, photo:earrings_5, name:'K5'},
    {id:5, photo:earrings_6, name:'K6'},
    {id:6, photo:earrings_7, name:'K7'},
]

const EARRINGS_SECTION_TEXTS = {
    PL : {
        'Title' : 'NATAN COLLECTION',
        'Title_text' : 'KOLCZYKI O UNIKALNYCH KSZTAŁTACH',
        'Text' : 'Odkryj naszą kolekcję złotych kolczyków z kryształami kwarcu, które dodadzą Twojej stylizacji niepowtarzalnego blasku i elegancji. Wykonane z dbałością o detale oraz z użyciem wysokiej jakości materiałów, nasze kolczyki są idealnym wyborem dla kobiet ceniących sobie wyrafinowany gust. Dostępne w różnorodnych wzorach i kształtach, gwarantują one subtelny, a zarazem efektowny wygląd. Niezależnie od okazji, nasze kolczyki stanowią doskonałą biżuterię, która podkreśli Twoją naturalną piękność. Spraw sobie lub bliskiej osobie radość, sięgając po nasze wyjątkowe kolczyki już teraz!',
        'Button_text' : 'Dowiedz się więcej',
    },
    EN : {
        'Title' : 'NATAN COLLECTION',
        'Title_text' : 'EARRINGS WITH UNIQUE SHAPES',
        'Text' : 'Discover our collection of gold earrings with quartz crystals that will add unique shine and elegance to your style. Made with attention to detail and using high-quality materials, our earrings are the perfect choice for women who value refined taste. Available in a variety of patterns and shapes, they guarantee a subtle yet effective look. Regardless of the occasion, our earrings are the perfect jewelry that will highlight your natural beauty. Make yourself or a loved one happy by purchasing our unique earrings now!',
        'Button_text' : 'Find out more',
    }
}

export {EARRINGS_SECTION_TEXTS, EARRINGS_SECTION_PHOTOS};