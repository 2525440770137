import { Typography } from "@mui/material";
import styles from "./MainHomepageSlider.module.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y} from "swiper/modules";
import ring_desktop from "../../../Images/Homepage/main_slider_pictures/ring.jpeg";
import ring2_desktop from "../../../Images/Homepage/main_slider_pictures/ring2.jpeg";
import ring_mobile from "../../../Images/Homepage/main_slider_pictures/ring_mobile.jpeg";
import ring2_mobile from "../../../Images/Homepage/main_slider_pictures/ring2_mobile.jpeg";
import { MAIN_HOMEPAGE_SWIPER_TEXT_PHOTO_1, MAIN_HOMEPAGE_SWIPER_TEXT_PHOTO_2 } from "../../Constants/MainHomepageSwiperSection";
import { useContext } from "react";
import NavigateContext from "../../../Context/NavigateContext";
const MainHomepageSlider =()=>{
    
    const navigateContext = useContext(NavigateContext)

    return(
        <div className={styles.main_homepage_slider}>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                navigation = {true}
                pagination={{ clickable: true, type:'none' }}
                loop={true}
                speed={500}
                >
                    <SwiperSlide className={styles.slide}>
                        <div>
                            <div className={styles.text_container_1} onClick={()=>{document.getElementById('diamonds_collection_div').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })}}>
                            <Typography className={styles.header_text_1} fontSize={navigateContext.biggerLetters ? '25px !important' : '20px !important'}>{MAIN_HOMEPAGE_SWIPER_TEXT_PHOTO_1[navigateContext.language].header }</Typography>
                            <Typography className={styles.text_1} fontSize={navigateContext.biggerLetters ? '20px !important' : '16px !important'}>{MAIN_HOMEPAGE_SWIPER_TEXT_PHOTO_1[navigateContext.language].text}</Typography>
                            </div>
                            <img className={styles.desktop_picture} src={ring_desktop}/>
                            <img className={styles.mobile_picture} src={ring_mobile}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.slide}>
                        <div>
                        <div className={styles.text_container_2}>
                            <Typography className={styles.header_text_2} fontSize={navigateContext.biggerLetters ? '25px !important' : '20px !important'}>{MAIN_HOMEPAGE_SWIPER_TEXT_PHOTO_2[navigateContext.language].header}</Typography>
                            <Typography className={styles.text_2} fontSize={navigateContext.biggerLetters ? '20px !important' : '16px !important'}>{MAIN_HOMEPAGE_SWIPER_TEXT_PHOTO_2[navigateContext.language].text}</Typography>
                            </div>
                            <img className={styles.desktop_picture} src={ring2_desktop}/>
                            <img className={styles.mobile_picture} src={ring2_mobile}/>
                        </div>
                    </SwiperSlide>
            </Swiper>
        </div>
    )
}
export default MainHomepageSlider;