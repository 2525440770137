import styles from './ProcessCard.module.css';
import { Grid, Typography } from "@mui/material";
import SectionTitle from '../MODELS/SectionTitle/SectionTitle';
import SectionText from '../MODELS/SectionText/SectionText';
import './ProcessCard.css'

const ProcessCard = ({title, text, image, className}) =>{

    return (
        <Grid container className={className} mb={2}>

            {className === 'card_right' && 
            <Grid container item xs={2} className={styles.line_container}>
                <div className={styles.line}></div>
            </Grid>
            }
            
            <Grid item xs={10} className={styles.process_container}>
                <Grid container itam xs={12} className={styles.process_title_container}>
                    <SectionTitle text={title} color={'white'}/>
                </Grid>
                <div className={styles.process_description_container}>
                    <Grid container itam xs={12} className={styles.process_image_container}><img className={styles.process_image} src={image}/></Grid>
                    <Grid container itam xs={12} className={styles.process_text_container}><SectionText text={text} color={'white'}/></Grid>
                </div>
            
            </Grid>
            {className === 'card_left' && 
            <Grid container item xs={2} className={styles.line_container}>
                <div className={styles.line}></div>
            </Grid>
            }
        </Grid>
    )
}
export default ProcessCard;