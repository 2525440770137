import { Grid, Typography } from "@mui/material";
import SectionText from "../../MODELS/SectionText/SectionText";
import styles from './DiamondsCollectionSection.module.css';
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "../../MODELS/Button/Button";
import SectionTitle from "../../MODELS/SectionTitle/SectionTitle";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import {DIAMONDS_COLLECTION_SECTION, DIAMONDS_COLLECTION_SECTION_TEXT} from "../../Constants/DiamondsCollectionSection";
import { useContext, useEffect } from "react";
import NavigateContext from "../../../Context/NavigateContext";
import './DiamondsCollectionSection.css'

const DiamondsCollectionSection = ({slidesCount}) =>{
    const navigateContext = useContext(NavigateContext)

    useEffect(()=>{

        const section = document.getElementById('diamonds_collection_section');
        const sectionObserver = new IntersectionObserver(entries=>{
            entries.forEach(entry=>{
                entry.target.classList.toggle('diamonds_collection_section_active', entry.isIntersecting);
                if(entry.isIntersecting) sectionObserver.unobserve(entry.target)
            })
    },{threshold:.5})
    sectionObserver.observe(section);
},[])

    return(
        <>
        <SectionTitle title={DIAMONDS_COLLECTION_SECTION_TEXT[navigateContext.language].Title} text={DIAMONDS_COLLECTION_SECTION_TEXT[navigateContext.language].Title_text} mt={5} id={'diamonds_collection_div'}/>
        <SectionText xs={11} md={6} mt={5} text={DIAMONDS_COLLECTION_SECTION_TEXT[navigateContext.language].Text} id={'diamonds_collection_section'}/>
        <Grid container item justifyContent={'center'} alignItems={'center'} mt={5} xs={12}>
            <div className={styles.main_container}>
                <Grid container item xs={12}>
                    <Swiper 
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={30}
                        slidesPerView={slidesCount === 1 ? (navigateContext.biggerLetters ? 1 : 2) : slidesCount}
                        navigation = {true}
                        pagination={{ clickable: true, type:'none' }}
                        loop={true}
                        autoplay={{delay:3000}}
                        speed={1000}
                        >
                        {DIAMONDS_COLLECTION_SECTION.map((item)=>{
                            return(
                                <SwiperSlide key={item.id}>
                                    <div onClick={window.screen.width <= 900 ? ()=>{navigateContext.setProductModalData(item); setTimeout(()=>{document.body.style.overflow='hidden'}, 200)} : null}>
                                    {window.screen.width > 900 && 
                                        <div className={styles.hidden_product_info_container}>
                                            <div>
                                                <SectionTitle text={item.name}/>
                                                <Button text={DIAMONDS_COLLECTION_SECTION_TEXT[navigateContext.language].Button_text} mt={5} onClick={()=>{navigateContext.setProductModalData(item); setTimeout(()=>{document.body.style.overflow='hidden'}, 200)}}/>
                                            </div>
                                        </div>
                                    }
                                        <img className={styles.image} src={item.photo}/>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </Grid>
            </div>
        </Grid>
        </>
    )
}
export default DiamondsCollectionSection;