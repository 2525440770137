import styles from "./Navbar.module.css";
import { Grid, Switch, Typography } from "@mui/material";
import Natan_Group from "../../Images/Logo/Natan_Group.png"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VscMenu } from "react-icons/vsc";
import { AiOutlineClose } from "react-icons/ai";
import polandIcon from "../../Images/Navbar/poland.png";
import englandIcon from "../../Images/Navbar/england.png";
import './Navbar.css'
import { useContext } from "react";
import NavigateContext from "../../Context/NavigateContext";
import { VscTextSize } from "react-icons/vsc";
import { NAVBAR } from "../Constants/Navbar";

const Navbar =()=>{
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false)
    const navigateContext = useContext(NavigateContext)


    const checkNavbar =()=>{
        const navbar_desktop = document.getElementById('navbar_desktop')
        const navbar_mobile = document.getElementById('navbar_mobile')
        const bigger_letters = document.getElementById('bigger_letters')
        const chosen_link_desktop = document.querySelector('.navigation_link_chosen')
        const chosen_link_mobile = document.querySelector('.navigation_link_mobile_chosen')
        if(window.scrollY === 0){
            navbar_desktop.classList.add('navbar_desktop_top')
            navbar_mobile.classList.add('navbar_mobile_top')
            chosen_link_desktop.classList.add('link_on_top')
            chosen_link_mobile.classList.add('link_on_top')
            bigger_letters.classList.add('bigger_letters_on_top')
            }
        else if(navbar_desktop.classList.contains('navbar_desktop_top')){
            navbar_desktop.classList.remove('navbar_desktop_top')
            chosen_link_desktop.classList.remove('link_on_top')
            bigger_letters.classList.remove('bigger_letters_on_top')
        }
        else if (navbar_mobile.classList.contains('navbar_mobile_top')){
            navbar_mobile.classList.remove('navbar_mobile_top')
            chosen_link_mobile.classList.remove('link_on_top')
        }
    }
    useEffect(()=>{checkNavbar()},[window.location.pathname, navigateContext.biggerLetters])
    window.onscroll = () => {checkNavbar()}

        return (
        <>

        {/* DESKTOP */}
        
        <Grid container item className={`${styles.main_navbar_container} navbar_desktop_top`} xs={12} id={'navbar_desktop'}>

            <Grid container item xs={2} className={styles.select_language_section}>
                <div className={styles.country_flag_icon_container}>
                    <img src={polandIcon} className={navigateContext.language === 'PL' ? styles.country_flag_icon_chosen : styles.country_flag_icon} alt="country_icon" onClick={()=>navigateContext.setLanguage('EN')}/>
                    <img src={englandIcon} className={navigateContext.language === 'EN' ? styles.country_flag_icon_chosen : styles.country_flag_icon} alt="country_icon" onClick={()=>navigateContext.setLanguage('PL')}/>
                </div>
            </Grid>

            <Grid container item xs={8} className={styles.logo_and_navigation_section}>
                <Grid item xs={12} className={styles.logo_and_navigation_section_image_container}>
                    <img className={styles.logo_and_navigation_section_image} src={Natan_Group} alt="Natan Group" onClick={()=>navigate('/')}/>
                </Grid>
                <Grid item xs={12} className={styles.logo_and_navigation_section_links_container}>
                    <div className={styles.navigation_container}>
                        <Typography className={window.location.pathname === '/' ? 'navigation_link_chosen' : styles.navigation_link} onClick={()=>{navigate('/')}} fontSize={navigateContext.biggerLetters ? '15px !important' : '12px !important'}>{NAVBAR[navigateContext.language].Home}</Typography>
                        <Typography className={window.location.pathname === '/about' ? 'navigation_link_chosen' : styles.navigation_link} onClick={()=>{navigate('/about')}} fontSize={navigateContext.biggerLetters ? '15px !important' : '12px !important'}>{NAVBAR[navigateContext.language].About_us}</Typography>
                        <Typography className={window.location.pathname === '/contact' ? 'navigation_link_chosen' : styles.navigation_link} onClick={()=>{navigate('/contact')}} fontSize={navigateContext.biggerLetters ? '15px !important' : '12px !important'}>{NAVBAR[navigateContext.language].Contact}</Typography>
                    </div>
                </Grid>
            </Grid>

            <Grid container item xs={2}>
                <Grid container item xs={12} justifyContent={'flex-end'} alignItems={'flex-start'} pt={2} pr={2} >
                    <div id={'bigger_letters'} className={`bigger_letters ${navigateContext.biggerLetters ? 'bigger_letters_active' : ''}`}><VscTextSize size={30} onClick={()=>{navigateContext.setBiggerLetters(!navigateContext.biggerLetters)}} cursor={'pointer'}/></div>
                </Grid>
            </Grid>
        </Grid>

        {/* MOBILE */}

        <Grid container item className={`${styles.main_navbar_container_mobile} navbar_mobile_top`} xs={12} id={'navbar_mobile'}>

            <Grid container item className={styles.navbar_logo_container_mobile} xs={10}>
                <img className={styles.navbar_logo_mobile} src={Natan_Group} alt="Natan Group" onClick={()=>{navigate('/'); setMenuOpen(false)}}/>
            </Grid>
            <Grid container item className={styles.navibar_icon_container_mobile} xs={2}>
                <VscMenu className={styles.menu_icon} size={'10vw'} style={{maxWidth:'35px', maxHeight:'35px'}} onClick={()=>setMenuOpen(true)}/>
            </Grid>

        </Grid>

<Grid className={menuOpen ? styles.navbar_menu_container_active : styles.navbar_menu_container}>
            <Grid container item className={styles.navbar_menu_actions_container} xs={12}>
                <Grid container item xs={6}>
                    <img className={styles.navbar_menu_logo} src={Natan_Group} alt="Natan Group" onClick={(e)=>{navigate('/'); setMenuOpen(false)}}/>
                </Grid>
                <Grid container item justifyContent={'end'} p={2} xs={6}>
                    <AiOutlineClose cursor={'pointer'} size={30} onClick={()=>setMenuOpen(false)} className={styles.close_menu_icon}/>
                </Grid>
            </Grid>
            <Grid container item className={styles.navbar_menu_links_container} xs={12}>
                <Grid container item xs={12} ml={2}><Typography className={window.location.pathname === '/' ? 'navigation_link_mobile_chosen' : styles.navigation_link_mobile} onClick={()=>{navigate('/'); setMenuOpen(false)}} fontSize={navigateContext.biggerLetters ? '15px !important' : '12px !important'}>{NAVBAR[navigateContext.language].Home}</Typography></Grid>
                <Grid container item xs={12} mt={2} ml={2}><Typography className={window.location.pathname === '/about' ? 'navigation_link_mobile_chosen' : styles.navigation_link_mobile} onClick={()=>{navigate('/about'); setMenuOpen(false)}} fontSize={navigateContext.biggerLetters ? '15px !important' : '12px !important'}>{NAVBAR[navigateContext.language].About_us}</Typography></Grid>
                <Grid container item xs={12} mt={2} ml={2}><Typography className={window.location.pathname === '/contact' ? 'navigation_link_mobile_chosen' : styles.navigation_link_mobile} onClick={()=>{navigate('/contact'); setMenuOpen(false)}} fontSize={navigateContext.biggerLetters ? '15px !important' : '12px !important'}>{NAVBAR[navigateContext.language].Contact}</Typography></Grid>
                <Grid container item xs={12} sx={{borderBottom:'solid black 1px', margin:'40px 20px 10px 20px'}}></Grid>
                <Grid container item xs={12} mt={2} ml={2} alignItems={'center'}>
                    <Typography className={styles.navigation_link_mobile} fontSize='20px !important'>{NAVBAR[navigateContext.language].Bigger_letters}</Typography>
                    <Switch checked={navigateContext.biggerLetters} onChange={(e)=>{navigateContext.setBiggerLetters(e.target.checked)}}/>
                </Grid>
                <Grid container item xs={12} ml={2} mt={2} alignItems={'center'}>
                    <div className={styles.country_flag_icon_container}>
                    <img src={polandIcon} className={navigateContext.language === 'PL' ? styles.country_flag_icon_chosen : styles.country_flag_icon} alt="country_icon" onClick={()=>navigateContext.setLanguage('EN')}/>
                    <img src={englandIcon} className={navigateContext.language === 'EN' ? styles.country_flag_icon_chosen : styles.country_flag_icon} alt="country_icon" onClick={()=>navigateContext.setLanguage('PL')}/>
                    </div>      
                </Grid>
            </Grid>
        </Grid>
        </>
        
        )
}
    export default Navbar;