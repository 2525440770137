import { Grid } from '@mui/material';
import SectionTitle from '../../MODELS/SectionTitle/SectionTitle';
import ring_section_1 from "../../../Images/Homepage/ring_section1.jpeg";
import SectionText from '../../MODELS/SectionText/SectionText';
import Button from '../../MODELS/Button/Button';
import { useContext, useEffect } from 'react';
import './DiamondsProductsSection.css'
import { DIAMONDS_PRODUCTS_SECTION } from '../../Constants/DiamondsProductsSection';
import NavigateContext from '../../../Context/NavigateContext';
const DiamondsProductsSection =()=>{
    const navigateContext = useContext(NavigateContext)

    useEffect(()=>{
        const section = document.getElementById('diamonds_products_section')

        const sectionObserver = new IntersectionObserver(entries =>{
            entries.forEach(entry=>{
                entry.target.classList.toggle('diamonds_products_section_active', entry.isIntersecting)
                if(entry.isIntersecting) sectionObserver.unobserve(entry.target)
            })
        },{threshold: .5})
        sectionObserver.observe(section)
    },[])
        
    return(
        <Grid container item xs={12} alignItems={'center'} justifyContent={'center'} mt={5}>
            <Grid container item xs={12} md={10}>

                <Grid container item xs={12} md={6} alignItems={'center'} justifyContent={'center'}>
                    <img src={ring_section_1} alt={'ring'} id={'diamonds_product_section_image'}/>
                </Grid>

                <Grid container item xs={12} md={6} alignContent={'center'} justifyContent={'flex-start'} id={'diamonds_products_section'}>
                    <SectionTitle title={DIAMONDS_PRODUCTS_SECTION[navigateContext.language].Title} text={DIAMONDS_PRODUCTS_SECTION[navigateContext.language].Title_text} mt={5}/>
                    <SectionText mt={3} xs={11} md={8} text={DIAMONDS_PRODUCTS_SECTION[navigateContext.language].Text}/>
                    <Button text={DIAMONDS_PRODUCTS_SECTION[navigateContext.language].Button_text} mt={5} onClick={()=>{document.getElementById('diamonds_collection_div').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })}}/>
                </Grid>

            </Grid>
        </Grid>
    )
}
export default DiamondsProductsSection;