import { useContext } from 'react';
import styles from './SectionText.module.css';
import { Grid, Typography } from "@mui/material";
import NavigateContext from '../../../Context/NavigateContext';

const SectionText = ({text, xs, md, mt, id, color})=>{

    const navigateContext = useContext(NavigateContext)

    return(
        <Grid container item xs={12} justifyContent={'center'} alignItems={'center'} mt={mt?mt:0} id={id}>
            <Grid container item xs={xs} md={md} justifyContent={'center'} alignItems={'center'}>
                <Typography className={styles.text} fontSize={navigateContext.biggerLetters ? '18px !important' : '13px !important'} color={color}>{text}</Typography>
            </Grid>
        </Grid>
    )
}
export default SectionText;