import pendant_1 from '../../Images/Homepage/pendants_section/pendant_1.jpeg';
import pendant_2 from '../../Images/Homepage/pendants_section/pendant_2.jpeg';
import pendant_3 from '../../Images/Homepage/pendants_section/pendant_3.jpeg';
import pendant_4 from '../../Images/Homepage/pendants_section/pendant_4.jpeg';
import pendant_5 from '../../Images/Homepage/pendants_section/pendant_5.jpeg';
import pendant_6 from '../../Images/Homepage/pendants_section/pendant_6.jpeg';
import pendant_7 from '../../Images/Homepage/pendants_section/pendant_7.jpeg';

const PENDANT_SECTION_PHOTOS =[
    {id:0, photo:pendant_1, name:'Z1'},
    {id:1, photo:pendant_2, name:'Z2'},
    {id:2, photo:pendant_3, name:'Z3'},
    {id:3, photo:pendant_4, name:'Z4'},
    {id:4, photo:pendant_5, name:'Z5'},
    {id:5, photo:pendant_6, name:'Z6'},
    {id:6, photo:pendant_7, name:'Z7'},
]

const PENDANT_SECTION_TEXTS={
    PL : {
        'Title' : 'UNIKALNE PROJEKTY',
        'Title_text' : 'ZAWIESZKI KTÓRE ZAPADAJĄ W PAMIĘĆ',
        'Text' : 'Odkryj bogactwo naszych zawieszek - tutaj znajdziesz niepowtarzalne wzory i style, które dodadzą wyjątkowego charakteru Twojej biżuterii. Nasze zawieszki to kwintesencja kreatywności i jakości wykonania, dostępne w różnorodnych materiałach i wzorach, abyś mógł znaleźć idealne dopasowanie do swojego stylu. Bez względu na to, czy preferujesz elegancję czy odważne akcenty, nasze zawieszki spełnią Twoje oczekiwania. Pozwól sobie na odrobinę wyjątkowości i stwórz biżuterię, która wyróżni Cię z tłumu.',
        'Button_text' : 'DOWIEDZ SIĘ WIĘCEJ',
    },
    EN : {
        'Title' : 'UNIQUE PROJECTS',
        'Title_text' : 'PENDANTS THAT WILL BE REMEMBERED',
        'Text' : 'Discover the wealth of our pendants - here you will find unique patterns and styles that will add a unique character to your jewelry. Our pendants are the essence of creativity and quality of workmanship, available in a variety of materials and patterns, so you can find the perfect match to your style. Regardless of whether you prefer elegance or bold accents, our pendants will meet your expectations. Allow yourself a bit of uniqueness and create jewelry that will make you stand out from the crowd.',
        'Button_text' : 'FIND OUT MORE',
    }
}

export {PENDANT_SECTION_PHOTOS, PENDANT_SECTION_TEXTS}