import { Grid, Typography } from "@mui/material";
import styles from './Footer.module.css'
import { SiGmail } from "react-icons/si";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const Footer =()=>{
    const location = useLocation();
    if (location.pathname === '/contact') return (<></>)
        return(
            <Grid container item className={styles.main_footer_container} id='main_footer_container'  mt={5}>
                <Grid container item xs={7}className={styles.footer_left_container}>
                    <Grid container item xs={12} className={styles.footer_reserved_container} alignItems={'flex-end'} pl={1}>
                            <div>
                            <Grid container item xs={12}><Typography fontFamily={'italiano'} fontWeight={600} className={styles.footer_reserved_text}>Wszelkie prawa zastrzerzone</Typography></Grid>
                            <Grid container item xs={12}><Typography fontFamily={'italiano'} fontWeight={600} className={styles.footer_reserved_text}>©NATANCOLLECTION.PL</Typography></Grid>
                            </div>
                    </Grid>
                </Grid>

                <Grid container item xs={5} className={styles.footer_right_container}>

                    <Grid container item xs={0} md={6} className={styles.footer_logo_container} alignContent={'center'} justifyContent={'center'}>
                        <a href="#" className={styles.footer_icon_href}><SiGmail className={styles.footer_icon_mail}/></a>
                        <a href="https://web.whatsapp.com/" className={styles.footer_icon_href}><IoLogoWhatsapp className={styles.footer_icon_whattsup}/></a>
                        <a href="https://www.facebook.com/profile.php?id=100054280489738" className={styles.footer_icon_href}><FaFacebook className={styles.footer_icon_facebook}/></a>
                    </Grid>
                    <Grid container item xs={12} md={6} className={styles.footer_contact_container} alignItems={'flex-end'} justifyContent={'center'}>
                        <div>
                        <Grid container item xs={12}><Typography fontFamily={'italiano'} fontWeight={600} className={styles.footer_contact_text}>Kontakt</Typography></Grid>
                        <Grid container item xs={12}><Typography fontFamily={'italiano'} className={styles.footer_contact_text}>Telefon: 692 160 771</Typography></Grid>
                        <Grid container item xs={12}><Typography fontFamily={'italiano'} className={styles.footer_contact_text}>Email: natan-biuro@wp.pl</Typography></Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        )
}
export default Footer;