import {useContext, useEffect, useState } from "react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import MainHomepageSlider from "./MainHomepageSlider/MainHomepageSlider";
import SectionTitle from "../MODELS/SectionTitle/SectionTitle";
import DiamondsProductsSection from "./DiamondsProductsSection/DiamondsProductsSection";
import NewCollectionSection from "./NewCollectionSection/NewCollectionSection";
import VideoSection from "./VideoSection/VideoSection";
import DiamondsCollectionSection from "./DiamondsCollectionSection/DiamondsCollectionSection";
import PendantsSection from "./PendantsSection/PendantsSection";
import NavigateContext from "../../Context/NavigateContext";
import EarringsSection from "./EarringsSection/EarringsSection";
import ProductModal from "../MODELS/ProductModal/ProductModal";
import { createPortal } from "react-dom";


const Homepage=() =>{

    const [slidesCount, setSlidesCount] = useState(4);
    const navigateContext = useContext(NavigateContext)

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const checkWidth = () =>{
        if(window.screen.width <= 600 || window.innerWidth <= 600){
            setSlidesCount(1)
        }else if(window.screen.width <= 900 || window.innerWidth <= 900){
            setSlidesCount(2)
        }else if(window.screen.width<= 1200 || window.innerWidth <= 1200){
            setSlidesCount(3)
        }else{
            setSlidesCount(4)
        }
    }
    useEffect(()=>{
        checkWidth()
    },[])

    window.addEventListener('resize', checkWidth);
    return(
        <>
            {navigateContext.productModalData && createPortal(<ProductModal/>, document.getElementById('modal'))}      
            <div style={{overflow:'hidden'}}>
                <MainHomepageSlider/>

                <SectionTitle title={'NATAN COLLECTION'} text={navigateContext.language ==='PL' ? 'Z WAMI OD PONAD DWÓCH DEKAD' : 'WITH YOU FOR OVER TWO DECADES'} mt={5} />

                <DiamondsProductsSection/>

                <NewCollectionSection slidesCount={slidesCount}/>

                {/* <VideoSection/> */}

                <DiamondsCollectionSection slidesCount={slidesCount}/>

                <PendantsSection slidesCount={slidesCount}/>

                <EarringsSection slidesCount={slidesCount}/>
            </div>
        </>
    )
}
export default Homepage;