import { Divider, Grid, Typography } from "@mui/material";
import styles from './Contact.module.css'
import { useEffect, useState } from "react";
import SectionTitle from "../MODELS/SectionTitle/SectionTitle";

const Contact = ()=>{

    return (
        <Grid container className={styles.main_contact_container} id={'main_contact_container'} pt={window.screen.width >= 900 ? '120px' : '70px'}>

            <Grid item xs={12} className={styles.contact_google_maps_item}>
                <gmp-map center="52.5567463338145, 17.625699271825987" zoom="14" map-id="NatanCollection">
                    <gmp-advanced-marker position="52.5567463338145, 17.625699271825987" title="My location"></gmp-advanced-marker>
                </gmp-map>
            </Grid>

            <Grid container item xs={12} className={styles.contact_info_container}>
                    <SectionTitle color={'white'} text={'Natan Collection Sp. z o.o.'} textFontSize={'25px !important'} after={false}/>
                    <SectionTitle color={'white'} title={'Ul. Orcholska 64a'} titleFontSize={'15px !important'} after={false}/>
                    <SectionTitle color={'white'} title={'62-200 Gniezno'} titleFontSize={'15px !important'} after={false}/>
                    <SectionTitle color={'white'} title={'NIP: 784-251-62-51'} titleFontSize={'15px !important'} after={false}/>
                    <br/>
                    <SectionTitle color={'white'} title={'Telefon: 692 160 771'} mt={3} titleFontSize={'15px !important'} after={false}/>
                    <SectionTitle color={'white'} title={'Email: natan-biuro@wp.pl'} titleFontSize={'15px !important'} after={false}/>
            </Grid>

        </Grid>
    )
}
export default Contact;