import styles from './PendantsSection.module.css'
import pendant_section_1 from '../../../Images/Homepage/pendants_section/pendant_section_1.jpeg'
import { Grid } from '@mui/material'
import SectionTitle from '../../MODELS/SectionTitle/SectionTitle'
import SectionText from '../../MODELS/SectionText/SectionText'
import Button from '../../MODELS/Button/Button'
import { useContext, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'
import {PENDANT_SECTION_PHOTOS, PENDANT_SECTION_TEXTS} from '../../Constants/PendantsSection';
import NavigateContext from '../../../Context/NavigateContext'
import './PendantsSection.css'

const PendantsSection =({slidesCount}) =>{
    const navigateContext = useContext(NavigateContext)
    useEffect(()=>{
        const section = document.getElementById('pendant_section')
        const observer = new IntersectionObserver(entries =>{
            entries.forEach(entry=>{
                entry.target.classList.toggle('pendant_section_active', entry.isIntersecting)
                if(entry.isIntersecting) observer.unobserve(entry.target)
            })
        },{threshold:.3})
        observer.observe(section)
        })
    return (
        <>
        <Grid container item xs={12} mt={5} className={styles.main_container}>
            <Grid container item xs={12} md={10}>
                <Grid container item xs={12} md={6} alignContent={'center'} justifyContent={'center'}>
                    <img className={styles.image} src={pendant_section_1} alt={'pendant'}/>
                </Grid>

                <Grid container item xs={12} md={6} alignContent={'center'} justifyContent={'flex-start'} id={'pendant_section'}>
                    <div>
                    <SectionTitle mt={5} title={PENDANT_SECTION_TEXTS[navigateContext.language].Title} text={PENDANT_SECTION_TEXTS[navigateContext.language].Title_text}/>
                    <SectionText xs={11} md={8} mt={5} text={PENDANT_SECTION_TEXTS[navigateContext.language].Text}/>
                    </div>
                    <Button text={PENDANT_SECTION_TEXTS[navigateContext.language].Button_text} mt={5}/>
                </Grid>
            </Grid>
        <Grid container item justifyContent={'center'} alignItems={'center'} mt={5} xs={12}>
            <div className={styles.swiper_section}>
                <Grid container item xs={12}>
                    <Swiper 
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={30}
                        slidesPerView={slidesCount === 1 ? (navigateContext.biggerLetters ? 1 : 2) : slidesCount}
                        navigation = {true}
                        pagination={{ clickable: true, type:'none' }}
                        loop={true}
                        autoplay={{delay:3000}}
                        speed={1000}
                        >
                        {PENDANT_SECTION_PHOTOS.map((item)=>{
                            return(
                                <SwiperSlide key={item.id}>
                                    <div onClick={window.screen.width <= 900 ? ()=>{navigateContext.setProductModalData(item); setTimeout(()=>{document.body.style.overflow='hidden'}, 200)} : null}>
                                    {window.screen.width > 900 && 
                                        <div className={styles.hidden_product_info_container}>
                                            <div>
                                                <SectionTitle text={item.name}/>
                                                <Button text={PENDANT_SECTION_TEXTS[navigateContext.language].Button_text} mt={5} onClick={()=>{navigateContext.setProductModalData(item); setTimeout(()=>{document.body.style.overflow='hidden'}, 200)}}/>
                                            </div>
                                        </div>
                                    }
                                        <img className={styles.image} src={item.photo}/>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </Grid>
            </div>
        </Grid>
    </Grid>
        </>
    )
}
export default PendantsSection;