import { Grid, Typography } from "@mui/material";
import styles from "./SectionTitle.module.css";
import { useContext } from "react";
import NavigateContext from "../../../Context/NavigateContext";

const SectionTitle =({title, text, mt, id, color, after, titleFontSize, textFontSize})=>{

    const navigateContext = useContext(NavigateContext)

    return (
        <Grid container item xs={12} className={after==false ? styles.section_title : styles.section_title_after} mt={mt?mt:0} id={id}>
            <Grid item xs={12}>
                <Typography className={styles.section_title_header} fontSize={titleFontSize ? titleFontSize : navigateContext.biggerLetters ? '20px !important' : '15px !important'} color={color}>{title}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={styles.section_title_text} fontSize={textFontSize ? textFontSize : navigateContext.biggerLetters ? '30px !important' : '25px !important'} color={color}>{text}</Typography>
            </Grid>
        </Grid>
    )
}
export default SectionTitle;